import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import recursos from "../assets/images/noticias/recursos.json";
import forma from "../assets/images/formas/forma1.png";
import forma2 from '../assets/images/formas/pintura1.svg';

export function Noticias() {
  const navigate = useNavigate();
  const recursosArray = Object.values(recursos);

  // Estado para manejar el popup (modal) visible
  const [selectedNoticia, setSelectedNoticia] = useState(null);

  const openModal = (noticia) => {
    setSelectedNoticia(noticia);
  };

  const closeModal = () => {
    setSelectedNoticia(null);
  };

  return (
    <>
      <section id="game" className="flex bg-orange-50 w-full mx-auto relative overflow-hidden">
        <div className="mt-20 w-full mb-20 z-10">
          
          <p className="text-center text-2xl text-gray-800 w-full mmd:w-[85%] mx-auto md:2-[80%] md:text-xl">
          Noticias
          </p>
          <h2 className="title2 text-center text-gray-800 font-semibold w-full mmd:w-[85%] mx-auto md:2-[80%] md:text-2xl md:justify-center font-semibold ">
          Te contamos nuestras
              <span
                  className="bg-no-repeat bg-contain inline-block md:mt-2 font-bold  pt-2 px-6 md:pt-0 md:-mt-2"
                  style={{
                      backgroundImage: `url(${forma2})`,
                      backgroundSize: '100%',
                      backgroundPosition: 'center',
                    
                  }}
              >
                  novedades
              </span> 
          </h2>

          <div className="mt-10 m-auto w-full mmd:w-[85%] mx-auto md:2-[80%] flex column lg:flex-wrap lg:flex-row gap-6">
            <div className="mt-10 w-full mmd:w-[95%] mx-auto mmd:w-[90%] grid grid-cols-1 mmd:grid-cols-3 gap-6 md:w-[85%]">
              {recursosArray.map((item, index) => (
                <div
                  className="relative bg-white shadow-sm rounded-3xl transform transition duration-300 hover:translate-y-[-10px] hover:shadow-lg cursor-pointer"
                  key={index + 1}
                  onClick={() => openModal(item)} // Abre el modal
                >
                  {/* Imagen */}
                  <div
                    className="w-full h-[15rem] mx-auto grayscale-0 rounded-2xl shadow-lg md:h-[20rem]"
                    style={{
                      backgroundImage: `url(${item.img})`,
                      backgroundSize: "100%",
                      backgroundPosition: "center",
                      padding: ".8rem 1rem",
                      filter: "grayscale(5%)",
                    }}
                  ></div>

                  {/* Contenido */}
                  <div className="w-full p-2 mx-auto mb-4 text-center relative">
                    <h5 className="text-yellow-500 text-md text-left font-bold px-2">
                      {item.date}
                    </h5>
                    <h3 className="color6 text-2xl text-left font-bold px-2 mt-2">
                      {item.title}
                    </h3>

                    {/* Descripción breve */}
                    <p
                      className="text-justify text-lg text-gray-600 mt-4 px-2 overflow-hidden min-h-[72px]"
                    >
                      {item.description.slice(0, 100)}...
                      
                    </p>
                    <br />
                    <span className="text-gray-500 mx-auto text-center absolute bottom-0 cursor-pointer left-0 right-0"> Leer más </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <img
          src={forma}
          alt="background-shape"
          className="w-[55%] absolute top-[15rem] right-[-62rem] origin-left opacity-40 rotate-[-100Deg] overflow-hidden md:hidden"
        />
      </section>

           {/* Modal */}
           {selectedNoticia && (
        <div
          className="fixed inset-0 bg-black bg-opacity-80 bg-drop-blur flex items-center justify-center z-50 overflow-hidden"
          onClick={closeModal}
        >
          <div
            className="bg-white w-[90%] md:w-[98%] mdd:w-[70%] flex md:flex-col md:flex-row rounded-lg overflow-hidden h-[85vh]  md:overflow-y-scroll"
            onClick={(e) => e.stopPropagation()} // Evita cerrar al hacer clic dentro del modal
          >
            {/* Imagen */}
            <div className="mdd:w-1/2 mmd:h-[85%] w-[95%] ">
              <div
                className="w-full md:h-[30vh] h-[70%] mt-2 ml-2 mx-auto border-[.3rem] border-white"
                style={{
                  backgroundImage: `url(${selectedNoticia.img})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
              <div className="flex mb-4 pb-4 max-h-[30%]">
                <div
                  className="w-1/2 mx-auto md:h-[15vh] h-[30vh] ml-2 mt-2 border-[.3rem] border-white"
                  style={{
                    backgroundImage: `url(${selectedNoticia.img2})`,
                    backgroundSize: "cover",
                    backgroundPosition: `${selectedNoticia.date == "Noviembre 2024" ?"end":"center"}`,
                    
                  }}
                ></div>
                
                <div
                  className="w-1/2 md:h-[15vh] h-[30vh] ml-2 mt-2 mx-auto border-[.3rem] border-white border-r-[0rem]"
                  style={{
                    backgroundImage: `url(${selectedNoticia.img3})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></div>
              </div>
            </div>

            {/* Contenido */}
            <div className="w-full mdd:w-1/2 p-6 md:px-6 mmd:overflow-y-scroll">
              <h3 className="text-3xl font-bold text-gray-800 mdd:mt-6 pl-4">
                {selectedNoticia.title}
                
              </h3>
              <p className="text-yellow-500 text-md mt-2 pl-4">
                {selectedNoticia.date}
              </p>
              <p className="mt-4 text-gray-600 text-lg px-4">
                {selectedNoticia.description.split("\n").map((line, i) => (
                  <span key={i}>
                    {line}
                    <br />
                  </span>
                ))}
              </p>
              <button
                onClick={closeModal}
                className="mt-4 px-4 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 ml-4"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
