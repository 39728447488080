import React from "react";


import talento1 from '../assets/images/talento/talento1.png';
import talento2 from '../assets/images/talento/talento2.png';
import talento3 from '../assets/images/talento/talento3.png';
import talento4 from '../assets/images/talento/talento4.png';

const Card = ({ imgSrc, title, description, linkText, url }) => (
    <div className="w-full mmd:w-[45%] mlg:w-[22%] px-2 py-6 rounded-3xl mt-10 bg-white shadow-sm transform transition duration-300 hover:translate-y-[-10px] hover:shadow-lg">
        <img 
            src={imgSrc} 
            alt={title} 
            className="w-[95%] h-auto max-h-[11rem] md:max-h-[15rem] mx-auto mt-[-3rem] md:w-[80%]" 
        />
        <h3 className="color6 text-2xl text-center font-bold mt-6">{title}</h3>
        <p className="text-justify text-lg text-gray-600 mt-4 md:w-[85%] md:mx-auto">{description}</p>
        <div className="flex justify-center mt-6">
            {/*
            <a 
                href={url} 
                className="text-xl text-center text-gray-600 font-semibold  hover:text-yellow-500 py-2 px-4 transition-colors duration-200 absolute bottom-4"
            >
                {linkText}
            </a>
            */}
        </div>
    </div>
);

export function SuiteEducation() {
    return (
        <>
            <section id="suiteEducation" className="w-full flex">
                <div className="mt-2 w-full mb-12 md:-mt-4">
                    <h2 className="text-center text-3xl text-gray-800 mmd:font-semibold w-full mmd:w-full mx-auto md:w-[85%] md:text-lg md:text-justify">
                    Todo lo que necesitas para crear un <span className="font-bold md:font-semibold">Club Maker STEAM</span> en tu Escuela 
                       
                    </h2>

                    <div className="w-full md:w-[90%] mx-auto mt-8 flex flex-col mmd:flex-wrap mmd:flex-row justify-center gap-8 mmd:gap-4">
                        <Card 
                            imgSrc={talento1} 
                            title="ASESORAMIENTO Y SOPORTE" 
                            description="para guiarte desde la planificación inicial hasta la gestión diaria." 
                            linkText="Quiero más info" 
                            url="https://youtu.be/gT8J9mBsqm8?si=4MZOGkqvWRFKBrWE"
                        />
                        <Card 
                            imgSrc={talento2} 
                            title="SISTEMA DE GESTIÓN" 
                            description="para facilitar la administracion con mejor transparencia y control." 
                            linkText="Quiero más info" 
                            url="#form"
                        />
                        <Card 
                            imgSrc={talento3} 
                            title="FORMACIÓN DE FORMADORES" 
                            description="con Cursos intensivos y prácticos con Certificación avalada por UTN." 
                            linkText="Quiero más info" 
                            url="#form"
                        />
                        <Card 
                            imgSrc={talento4} 
                            title="KITS DE PROYECTOS MAKER STEAM" 
                            description="con materiales, guías y recursos para brindar clases innovadoras." 
                            linkText="Quiero más info" 
                            url="#form"
                        />
                    </div>
                </div>
            </section>
        </>
    );
}
