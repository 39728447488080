import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useLocation } from 'react-router-dom';

import forma2 from '../assets/images/formas/pintura1.svg';

export function Metodo() {
    const location = useLocation();

    useEffect(() => {
    if (location.hash) {
        const element = document.getElementById(location.hash.substring(1));
        if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        }
    }
    }, [location]);

    const fadeInUp = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    };

    return (
        <>  
            <section id="metodo"  className="w-screen flex bg-orange-50 overflow-hidden md:-mt-28 relative " >
                <div className="mt-20 w-full min-h-screens mmd:w-full mb-10 ">
                <p className="text-center text-2xl text-gray-800 w-full mmd:w-[85%] mx-auto md:2-[80%] md:text-xl">
                Metodología
                    </p>
                    <h2 className="title2 text-center text-gray-800 font-semibold w-full mmd:w-[85%] mx-auto md:w-[90%] md:text-2xl md:justify-center font-semibold ">
                        ¿Cómo te aseguramos un 
                        <span
                            className="bg-no-repeat bg-contain inline-block md:mt-2 font-bold pt-2 px-6 md:pt-0 md:-mt-2"
                            style={{
                                backgroundImage: `url(${forma2})`,
                                backgroundSize: '100%',
                                backgroundPosition: 'center',
                  
                            }}
                        >
                            aprendizaje
                        </span> atractivo?
                    </h2>
                    
                    <div   className="flex flex-col mmd:flex-row justify-center text-center mx-auto items-center">
                        <div className="z-20 w-full mmd:w-2/4 text-left mt-6 text-1 mx-auto ml-48 2xl:ml-32 md:mx-auto  md:hidden">

                            <h3 className="text-5xl  md:text-3xl md:text-center md:text-center animate-fade animate-once animate-duration-[2000ms] animate-ease-linear ">Diseñamos con <br />metodología<br />
                            <span 
                            className="bg-no-repeat bg-contain inline-block md:mt-2 mt-2 mmd:ml-[-3rem] font-semibold"
                            style={{
                                backgroundImage: `url(${forma2})`,
                                backgroundSize: '100%',
                                backgroundPosition: 'center',
                                padding: '.4rem 3rem'
                            }}
                            >nómadeSTEAM
                            </span></h3>
                            <p className="z-20 relative text-2xl mt-4 text-justify inline-block 
                            md:text-lg w-[72%] md:ml-10 md:justify-center ">
                                que garantiza un aprendizaje más atractivo y eficiente fundamentándose en tres pilares clave:
                            </p>
                            <a 
                                className="mmd:mx-6 mmd:text-2xl text-xl mmd:ml-[0rem] mt-8 text-center border-gray-700 border-2 rounded-full py-2 px-12 inline-block text-gray-700 hover:bg-amber-400 md:ml-14 font-semibold" 
                                href="/nosotros"
                            >
                            NOSOTROS
                            </a>
                        </div>
                        
                        <div className="w-full md:w-[90%] mmd:w-[50%] text-center mt-12 text-1 mx-auto mmd:mr-28 2xl:mr-4 md:mt-12 md:px-2 z-10 md:text-center md:items-aling">
                            {["🎲", "💡", "📈"].map((emoji, index) => (
                                <motion.div
                                    key={index}
                                    className="duration-700 w-full mmd:w-[90%]  min-h-[10rem] md:min-h-[13rem] rounded-2xl p-8 bg-white mb-8 md:mx-auto md:text-center"
                                    initial="hidden"
                                    whileInView="visible"
                                    variants={fadeInUp}
                                    viewport={{ once: true }}
                                >
                                    <h5 className="text-5xl 4xl:text-6xl flex text-left items-center absolute md:align-center md:mt-8 md:-ml-6">{emoji}</h5>
                                    <h4 className="ml-20 block text-2xl 4xl:text-3xl text-left font-bold md:ml-14 md:text-2xl">{getMetodoTitle(index)}</h4>
                                    <p className="des ml-20 4xl:text-xl block text-justify md:ml-14">{getMetodoText(index)}</p>
                                </motion.div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

function getMetodoTitle(index) {
    const titles = ["Gamificación", "Design thinking", "Aprendizaje por desafíos"];
    return titles[index];
}

function getMetodoText(index) {
    const texts = [
        "Utilizamos dinámicas lúdicas para aumentar el interés y la motivación de los equipos.",
        "Fomentamos el pensamiento crítico y la creatividad para resolver problemas complejos.",
        "Proponemos retos que incentivan la participación activa y el compromiso."
    ];
    return texts[index];
}
