import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";

import forma2 from "../assets/images/formas/pintura1.svg";
import forma from '../assets/images/formas/forma1.png';
import { SuiteCompany } from "./suiteCompany"; // Importa tu componente suiteCompany
import { SuiteEducation } from "./suiteEducation"; // Importa tu componente suiteEducation

export function Soluciones() {
    const navigate = useNavigate();
    const [currentSolution, setCurrentSolution] = useState(0);

    const { ref, inView } = useInView({
        triggerOnce: true, // La animación se dispara solo una vez
        threshold: 0.2, // Ajusta cuánto del componente tiene que estar visible antes de disparar
    });

    return (
        <section id="soluciones" className="w-screen flex bg-orange-50 relative">
            
            <div className="mt-20 w-full md:mb-20 z-10">
                
                {/* Títulos */}
                <p className="text-center text-2xl text-gray-800 w-full mmd:w-[85%] mx-auto md:w-[70%] md:text-xl">
                    Soluciones
                </p>
                <h2 className="title2 text-center text-gray-800 font-semibold w-full mmd:w-[85%] mx-auto md:2-[80%] md:text-2xl">
                    ¿Qué te  
                    <span
                        className="bg-no-repeat bg-contain inline-block md:mt-2 font-bold pt-2 px-6 md:pt-0"
                        style={{
                            backgroundImage: `url(${forma2})`,
                            backgroundSize: "100%",
                            backgroundPosition: "center",
                           
                        }}
                    >
                        proponemos
                    </span>
                    según tu ámbito?
                </h2>

                {/* Botones de selección */}
                <div className="mmd:bg-white mmd:w-[85%] mx-auto md:w-[95%] rounded-3xl mt-10 md:-mt-6 py-6 justify-center">
                    <div className="mt-10 flex justify-center space-x-4 mb-4 rounded-full bg-gray-300 w-[90%] mmd:w-[40%] mx-auto">
                        <button
                            className="text-2xl px-12 md:px-4 py-2 font-bold text-gray-800 rounded-full hover:bg-gray-700 focus:bg-gray-700 hover:text-white focus:text-white md:text-xl"
                            onClick={() => setCurrentSolution(0)}
                            style={{
                                backgroundColor: currentSolution === 0 ? "rgb(55 65 81)" : "",
                                color: currentSolution === 0 ? "#ffff" : "",
                            }}
                        >
                            CORPORATIVO
                        </button>
                        <button
                            className="text-2xl px-12 md:px-4 py-2 font-bold text-gray-800 rounded-full hover:bg-gray-700 focus:bg-gray-700 hover:text-white focus:text-white md:text-xl"
                            onClick={() => setCurrentSolution(1)}
                            style={{
                                backgroundColor: currentSolution === 1 ? "rgb(55 65 81)" : "",
                                color: currentSolution === 1 ? "#ffff" : "",
                            }}
                        >
                            EDUCATIVO
                        </button>
                    </div>

                    {/* Componente dinámico basado en currentSolution */}
                    <div
                        ref={ref}
                        className="mt-10 m-auto w-full mmd:w-[85%] mx-auto md:2-[80%] flex flex-col gap-6 min-h-[250px]"
                    >
                        {currentSolution === 0 ? (
                            <SuiteCompany />
                        ) : (
                            <SuiteEducation />
                        )}
                    </div>
                </div>
            </div>
            <img
                src={forma}
                alt="background-shape"
                className="w-[55%] absolute top-[-15rem] left-[-5rem] origin-left opacity-40 rotate-90 overflow-hidden md:hidden"
            />
        </section>
    );
}
