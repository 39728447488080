import React, { useState } from "react";
import forma2 from '../assets/images/formas/pintura1.svg';

export function DesprogramadosSolutions() {
    const [currentSolution, setCurrentSolution] = useState(1);

    const empresa = {
        1: {
            title: 'Para la empresa:',
            des: "Fomenta la cohesión y el compromiso en los equipos de trabajo. Promueve una cultura de impacto social con donaciones educativas. Posicionamiento en redes por su compromiso con la educación y la sostenibilidad."
        },
        2: {
            title: 'Para los colaboradores:',
            des: "   Desarrollan habilidades clave como la colaboración, liderazgo, comunicación, adaptabilidad entre otras. Obtienen insignias verificables   agregando valor a sus perfiles.Refuerzan el sentido de pertenencia y propósito."
        },
        3: {
            title: 'Para los clientes:',   
            des: "Reciben una experiencia única en eventos corporativos que fortalece la conexión con la cultura de la empresa. Aumentarán su fidelidad y satisfacción por su participación en iniciativas de alto impacto social y educativo."
        }
    };

    const escuela = {
        1: {
            title: 'Para las instituciones:',
            des: "Reciben kits didácticos gratuitos, gracias a las donaciones empresariales. Acceden a un material didáctico que facilita la enseñanza STEAM y pensamiento computacional sin dispositivos."
        },
        2: {
            title: 'Para los docentes:',   
            des: "Pertenecen a una comunidad educativa con acceso a recursos de apoyo e instructivos. Ganan nuevas herramientas que mejoran sus clases y motivan a los estudiantes."
        },
        3: {
            title: 'Para los estudiantes:',
            des: "Desarrollan habilidades esenciales para su futuro como pensamiento computacional y resolución de problemas. Experimentan aprendizaje de forma práctica y lúdica, sin depender de pantallas. Reciben una preparación adaptada a los desafíos de una sociedad digital."
        }
    };

    const solutions = currentSolution === 1 ? empresa : escuela;

    return (
        <>
            <section id="beneficios" className="flex bg-orange-50 w-full mx-auto">
                <div className="mt-20 w-full mb-20 ">
                    <p className="text-center text-2xl text-gray-800 w-full mmd:w-[85%] mx-auto md:2-[80%] md:text-xl">
                        Beneficios
                    </p>
                    <h2 className="title2 text-center text-gray-800 font-semibold w-full mmd:w-[85%] mx-auto md:w-[85%] md:text-2xl">
                        ¿Cómo
                        <span
                            className="bg-no-repeat bg-contain inline-block md:mt-2 font-bold pt-2 px-6 md:pt-0"
                            style={{
                                backgroundImage: `url(${forma2})`,
                                backgroundSize: '100%',
                                backgroundPosition: 'center',
                            
                            }}
                        >
                            te suma
                        </span> desprogramados?
                    </h2>
                    

                    <div className="bg-white mmd:w-[85%] mx-auto md:w-[95%] rounded-3xl mt-10 md:-mt-6 py-6 justify-center">
                    <div className="mt-10 flex justify-center space-x-4 mb-4 rounded-full bg-gray-300 w-[80%] mmd:w-[40%] mx-auto">
                        <button
                            className="text-2xl px-12 md:px-4 py-2 font-bold text-gray-800 rounded-full hover:bg-gray-700 focus:bg-gray-700 hover:text-white focus:text-white md:text-xl"
                            onClick={() => setCurrentSolution(1)}
                            style={{
                                backgroundColor: currentSolution === 1 ? "rgb(55 65 81)" : "",
                                color: currentSolution === 1 ? "#ffff" : "",
                            }}
                        >
                            EMPRESAS
                        </button>
                        <button
                            className="text-2xl px-12 md:px-4 py-2 font-bold text-gray-800 rounded-full hover:bg-gray-700 focus:bg-gray-700 hover:text-white focus:text-white md:text-xl"
                            onClick={() => setCurrentSolution(2)}
                            style={{
                                backgroundColor: currentSolution === 2 ? "rgb(55 65 81)" : "",
                                color: currentSolution === 2 ? "#ffff" : "",
                            }}
                        >
                            ESCUELAS
                        </button>
                    </div>

                    <div className="mt-10 m-auto w-full mmd:w-[85%] mx-auto md:2-[80%] flex column lg:flex-wrap lg:flex-row gap-6 min-h-[250px]">
                        {Object.values(solutions).map((item, index) => (
                            <div key={index} className="mx-auto w-[95%] mdd:w-[30%]">
                                <h3 className="text-2xl font-semibold text-center">
                                    {item.title}
                                </h3>
                                <p className="text-lg text-justify w-[90%] mx-auto">
                                    {item.des}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
                </div>
                
            </section>
        </>
    );
}
