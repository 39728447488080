import React from "react";

import solucion1 from '../assets/images/soluciones/solucion1.png';
import solucion2 from '../assets/images/soluciones/solucion7.png';
import solucion3 from '../assets/images/soluciones/solucion3.png';
import solucion4 from '../assets/images/soluciones/solucion8.png';

// Componente de tarjeta reutilizable
const Card = ({ imgSrc, title, description, linkText, url }) => (
    <div className="w-full mmd:w-[45%] mlg:w-[22%] px-2 py-6 rounded-3xl mt-10 bg-white shadow-sm transform transition duration-300 hover:translate-y-[-10px] hover:shadow-lg">
        <img 
            src={imgSrc} 
            alt={title} 
            className="w-[95%] h-auto max-h-[10rem] md:max-h-[15rem]  mx-auto mt-[-3rem] md:w-[80%] " 
        />
        <h3 className="color6 text-2xl text-center font-bold mt-6">{title}</h3>
        <p className="text-justify text-lg text-gray-600 mt-4 md:w-[85%] md:mx-auto">{description}</p>
        <div className="flex justify-center mt-6">
            {/*
            <a 
                href={url} 
                className="text-xl text-center text-gray-600 font-semibold  hover:text-yellow-500 py-2 px-4 transition-colors duration-200 absolute bottom-4"
            >
                {linkText}
            </a>
            */}
        </div>
    </div>
);


export function SuiteCompany() {
    return (
        <>
            <section id="suiteCompany" className="w-full flex">
                <div className="mt-2 w-full mb-12 md:-mt-4">
                    <h2 className="text-center text-3xl text-gray-800 mmd:font-semibold w-full mmd:w-full mx-auto md:w-[85%] md:text-lg md:text-justify">
                    Todo lo que necesitas para tu equipo e impacto en tu comunidad
                       
                    </h2>

                    <div className="w-full md:w-[90%] mx-auto mt-8 flex flex-col mmd:flex-wrap mmd:flex-row justify-center gap-8 mmd:gap-4">
                        <Card 
                            imgSrc={solucion1} 
                            title="ACTIVIDADES TEAM BUILDING" 
                            description="para fortalecer la cohesion de tu equipo y transmitir cultura." 
                            linkText="Quiero más info" 
                            url="#form"
                        />
                        <Card 
                            imgSrc={solucion2} 
                            title="CONSULTORIA EN CAPACITACIONES" 
                            description="para convertir la efectividad de tus entrenamientos in company." 
                            linkText="Quiero más info" 
                            url="#form"
                        />
                        <Card 
                            imgSrc={solucion3} 
                            title="PROGRAMAS DE BENEFICIOS" 
                            description="para impulsar el reconocimiento a tus colaboradores." 
                            linkText="Quiero más info" 
                            url="#form"
                        />
                        <Card 
                            imgSrc={solucion4} 
                            title="PROGRAMAS RSE VOCACIONALES" 
                            description="para mejorar la empleabilidad de jóvenes en oficios digitales." 
                            linkText="Quiero más info" 
                            url="#form"
                        />
                    </div>
                </div>
            </section>
        </>
    );
}
