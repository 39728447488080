import React from "react";


import ficha1 from '../assets/images/desprogramados/ficha0.png';
import ficha2 from '../assets/images/desprogramados/ficha1.png';
import ficha3 from '../assets/images/desprogramados/ficha3.png';


const Card = ({ imgSrc, number, title, description }) => (
    <div className="w-full mmd:w-[35%] mlg:w-[28%] px-6 py-8 rounded-3xl mt-10 bg-white shadow-sm transform transition duration-300 hover:translate-y-[-10px] hover:shadow-lg">
        <img 
            src={imgSrc} 
            alt={title} 
            className="w-[50%] h-auto mx-auto mt-[-5rem]" 
        />
        <h3 className="color6 text-2xl text-left font-bold  mt-6 px-2">{title}</h3>
        <p className="text-justify text-lg text-gray-600 mt-4 px-2">{description}</p>
   
    </div>
);

export function DesprogramadosGame() {
    return (
        <>
            <section id="implementacion" className="w-screen flex bg-orange-50">
                <div className="mt-20 w-full mb-20">
                    <p className="text-center text-2xl text-gray-800  w-full mmd:w-[85%] mx-auto md:2-[80%] md:text-xl ">
                        Implementación
                    </p>
                    <h2 className="text-center title2 text-gray-800 font-semibold w-full mmd:w-[85%] mx-auto md:w-[85%] md:text-2xl">
                    ¿Cómo potenciar tu <span className="font-bold">equipo</span> y transformar el <span className="font-bold">aula</span>?
                    </h2>

                    <div className="w-[95%] md:w-[85%] mx-auto mt-14 flex flex-col mmd:flex-wrap mmd:flex-row justify-center gap-6 mmd:gap-10">
                        <Card 
                            imgSrc={ficha1} 
                            number="1"
                            title="Diseñamos y preparamos tu experiencia" 
                            description="Empresas nos convocan para diseñar actividades de team building o eventos corporativos con impacto social. Tras una consultoría inicial, creamos una experiencia personalizada y enviamos un kit completo con todos los materiales necesarios, junto con soporte detallado para garantizar una implementación exitosa y sin complicaciones." 
                      
                        />
                        <Card 
                            imgSrc={ficha2} 
                            number="2"
                            title="Vive la experiencia y emite reconocimientos" 
                            description="Los equipos experimentan una dinámica que fortalece la cohesión y fomenta la colaboración. Al finalizar, los participantes dan feedback para medir el impacto de la activida y cada uno recibe una insignia digital en reconocimiento de las habilidades desarrolladas." 
                   
                        />
                        <Card 
                            imgSrc={ficha3} 
                            number="3"  
                            title="Dona tu kit y transforma un aula" 
                            description="Tras la dinámica, los kits se donan a una institución educativa elegida por los participantes, llevando el aprendizaje de programación y pensamiento computacional a las aulas sin necesidad de pantallas extendiendo el impacto a la comunidad." 
                      
                        />
                       
                    </div>
                </div>
            </section>
        </>
    );
}
