import React, { useState } from "react";

import forma1 from '../assets/images/formas/forma1.png';
import forma2 from '../assets/images/formas/pintura1.svg';
import imagen from '../assets/images/desprogramados/ficha.png';

export function Banner() {
    const [style, setStyle] = useState({
        rotateX: 0,
        rotateY: 0,
        shadowX: 0,
        shadowY: 0,
    });

    const handleMouseMove = (event) => {
        const { clientX, clientY, currentTarget } = event;

        const { offsetWidth, offsetHeight, offsetLeft, offsetTop } = currentTarget;
        const centerX = offsetLeft + offsetWidth / 2;
        const centerY = offsetTop + offsetHeight / 2;

        const x = (clientX - centerX) / offsetWidth; 
        const y = (clientY - centerY) / offsetHeight;

        setStyle({
            rotateX: y * 25, // Rotación vertical (eje X)
            rotateY: x * -25, // Rotación horizontal (eje Y)
            shadowX: x * 30, // Sombra horizontal
            shadowY: y * 30, // Sombra vertical
        });
    };

    const handleMouseLeave = () => {
        setStyle({
            rotateX: 0,
            rotateY: 0,
            shadowX: 0,
            shadowY: 0,
        });
    };

    return (
        <>
            <section id="banner" className="w-screen min-h-screen flex bg-orange-50 overflow-hidden">
                <div className="mt-20 w-full min-h-screens mmd:w-full mmd:p-10 relative overflow-hidden">
                    <div className="flex flex-col mmd:flex-row justify-center text-center mx-auto items-center mb-20">
                        <div className="w-full mmd:w-2/4 mmd:text-left mt-20 mx-auto ml-40 2xl:ml-20 md:mx-auto md:text-center md:mt-40 md:pt-4">
                            <h2 className="z-20 relative w-full text-justify mx-auto md:text-center
                            md:w-[90%] text-7xl 4xl:text-8xl md:text-[2.8rem] font-semibold">
                                el futuro es hoy<br />¿lo
                                <span
                                    className="bg-no-repeat bg-contain inline-block md:mt-2 animate-fade-up animate-duration-1000 animate-ease-linear font-bold pt-2 px-8 md:px-4 md:pt-0"
                                    style={{
                                        backgroundImage: `url(${forma2})`,
                                        backgroundSize: '100%',
                                        backgroundPosition: 'center',
                                     
                                    }}
                                >
                                    jugamos
                                </span>?
                            </h2>
                            <p className="z-20 relative text-2xl mt-4 text-justify  mmd:inline-block mx-auto md:text-xl mmd:w-[70%] w-[85%] md:hidden">
                                Ayudamos a que el aprendizaje sea más efectivo diseñando experiencias educativas con nuestra metodología STEAM y gamificada
                            </p>
                            <div className="mmd:flex md:mx-auto md:align-center md:mt-6 md:absolute md:bottom-[5rem] md:w-full">
                                <a className="mmd:mx-6 mmd:text-2xl text-xl mmd:ml-[0rem] mt-12 text-center border-gray-700 border-2 rounded-full py-2 px-12 md:px-4 mmd:inline-block text-gray-700 md:mt-10 hover:bg-amber-400 md:mx-auto md:z-10 md:relative font-semibold md:block md:w-[80%]" href="#metodo">
                                    CONOCENOS
                                </a>
                                <a className="mmd:mx-6  mmd:text-2xl text-xl mmd:ml-[0rem] mmd:mt-12 text-center bg-gray-700 border-2 border-gray-700 rounded-full py-2 px-12 md:px-4 mmd:inline-block text-white md:mt-4 hover:bg-amber-400 md:mx-auto md:z-10 md:relative md:block font-semibold md:w-[80%]" href="#soluciones">
                                    SOLUCIONES
                                </a>
                            </div>
                        </div>

                        {/* Imagen con efecto 3D y sombra dinámica */}
                        <div
                            className="4xl:w-[50%] w-[45%] z-20 absolute right-[1rem]
                            2xl:mt-8 md:top-[1rem] md:w-[45%] md:right-0 md:left-0 md:mx-auto"
                            style={{
                                transform: `rotateX(${style.rotateX}deg) rotateY(${style.rotateY}deg)`,
                                filter: `drop-shadow(${style.shadowX}px ${style.shadowY}px 10px rgba(60, 40, 0, 0.2))`,
                                transition: "transform 0.1s ease-out, filter 0.1s ease-out",
                            }}
                            onMouseMove={handleMouseMove}
                            onMouseLeave={handleMouseLeave}
                        >
                            <img src={imagen} alt="experiencias" />
                        </div>

                        <img src={forma1} alt="forma" className="w-[85%]  absolute bottom-[-30rem] right-[-50rem] origin-left opacity-[.3] md:right-[-8rem] md:bottom-[-2rem] md:w-[500%] 
                    4xl:w-[75%]" />
                    </div>
                </div>
            </section>
        </>
    );
}
