import React, { useRef } from "react";
import emailjs from '@emailjs/browser';
import forma2 from '../assets/images/formas/pintura1.svg';

export function DesprogramadosForm() {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('nomademaker', 'nomademaker', form.current, {
            publicKey: 'user_YZyG9rLPkZlHWdHNzVmMh',
          })
            .then((result) => {
                console.log(result.text);
                alert("Mensaje enviado correctamente!");
            }, (error) => {
                console.log(error.text);
                alert("Falló al enviar el mensaje, por favor intentalo de nuevo.");
            });

        
    };

    return (
        <>
            <section id="form" className="w-screen justify-center items-center mx-auto bg-orange-50">
                <br />
                <p className="text-center text-2xl text-gray-800 w-full mmd:w-[85%] mx-auto md:2-[80%] md:text-xl">
                    Contacto
                    </p>
                    <h2 className="title2 text-center text-gray-800 font-semibold w-full mmd:w-[85%] mx-auto md:2-[80%] md:text-2xl font-semibold">
                        ¿
                        <span
                            className="bg-no-repeat bg-contain inline-block md:mt-2 font-bold md:pt-0 md:-mt-2"
                            style={{
                                backgroundImage: `url(${forma2})`,
                                backgroundSize: '100%',
                                backgroundPosition: 'center',
                              
                            }}
                        >
                        Te sumás
                        </span> a este desafío?
                    </h2>
                
                    <div className="mx-auto text-center">
                    <a className="mmd:mx-6 mmd:text-2xl text-xl mx-2  mt-12 mx-auto text-center bg-gray-700 border-2 border-gray-700 rounded-full py-[.4rem] px-12 inline-block text-white md:mt-10 hover:bg-amber-400" href="https://calendly.com/hablemos-nomademakerspace/30min">
                        AGENDEMOS
                    </a>
                    <a id="formDes" className="mmd:mx-6 mmd:text-2xl text-xl mx-2  mt-12 mx-auto text-center border-gray-700 border-2 rounded-full py-[.4rem] px-12 inline-block text-gray-700 md:mt-4 hover:bg-amber-400" href="#formDes">
                        ESCRIBINOS
                    </a>
                    </div>
      
                    <div  className="mt-12 mx-auto w-[85%] md:w-[98%] p-6 bg-white rounded-lg shadow-lg">

                    <form ref={form} onSubmit={sendEmail} className="space-y-6 mx-auto " >
                        <div>
                            <label htmlFor="name" className="block text-lg font-medium text-gray-700">Nombre</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                className="mt-1 block w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:ring-color1 focus:border-color1"
                                placeholder="Tu nombre"
                                required
                            />
                        </div>

                        <div>
                            <label htmlFor="email" className="block text-lg font-medium text-gray-700">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                className="mt-1 block w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:ring-color1 focus:border-color1"
                                placeholder="tuemail@example.com"
                                required
                            />
                        </div>

                        <div>
                            <label htmlFor="message" className="block text-lg font-medium text-gray-700">Contanos el motivo de tu contacto</label>
                            <textarea
                                id="message"
                                name="message"
                                rows="5"
                                className="mt-1 block w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:ring-color1 focus:border-color1"
                                placeholder="Escribe aquí el motivo de tu contacto..."
                                required
                            ></textarea>
                        </div>

                        <div className="text-left ">
                            <button
                                type="submit"
                                className="mmd:mx-6 mmd:text-2xl text-xl mx-2  mt-12 mx-auto text-center bg-gray-700 border-2 border-gray-700 rounded-full py-[.4rem] px-12 inline-block text-white md:mt-10 hover:bg-amber-400"
                            >
                                ENVIAR
                            </button>
                        </div>
                    </form>
                </div>
            </section>
        </>
    );
}
