import React from "react";
import { useNavigate } from 'react-router-dom';
import forma2 from '../assets/images/formas/pintura1.svg';
import ficha from '../assets/images/desprogramados/ficha3.svg';
import recursos from '../assets/images/compromiso/recursos.json';
import forma from '../assets/images/formas/forma1.png';

import ods4 from '../assets/images/compromiso/ods4.png';
import ods5 from '../assets/images/compromiso/ods5.webp';
import ods8 from '../assets/images/compromiso/ods8.png';
import ods17 from '../assets/images/compromiso/ods10.webp';
import ods12 from '../assets/images/compromiso/ods12.JPG';
import ods10 from '../assets/images/compromiso/ods17.png';

const imageMap = {
    "ods4": ods4,
    "ods5": ods5,
    "ods8": ods8,
    "ods10": ods10,
    "ods12": ods12,
    "ods17": ods17,
}

export function Compromiso() {
    const navigate = useNavigate();
    const recursosArray = Object.values(recursos);

    return (
        <>
            <section id="game" className="flex bg-orange-50 w-full mx-auto relative">
                <div className="mt-20 w-full mb-20 z-10">
                    <p className="text-center text-2xl text-gray-800 w-full mmd:w-[85%] mx-auto md:2-[80%] md:text-xl">
                    Nuestro compromiso
                    </p>
                    <h2 className="title2 text-center text-gray-800 font-semibold w-full mmd:w-[85%] mx-auto md:w-full md:text-2xl md:justify-center font-semibold ">
                        Diseñamos alineados a los ODS para
                        <span
                            className="bg-no-repeat bg-contain inline-block md:mt-2 font-bold pt-2 px-6 md:pt-0 md:-mt-2"
                            style={{
                                backgroundImage: `url(${forma2})`,
                                backgroundSize: '100%',
                                backgroundPosition: 'center',
                                
                            }}
                        >
                            impactar
                        </span> 
                    </h2>

                    

                    <div className="mt-10 m-auto w-full mmd:w-[85%] mx-auto md:2-[80%] flex column lg:flex-wrap lg:flex-row  gap-6 md:w-[90%]">
                        <div className="mt-10 w-full mmd:w-[85%] mx-auto mmd:w-[90%] grid grid-cols-2  mmd:grid-cols-3 gap-6">
                        {recursosArray.map((item, index) => (
                            <div
                            className="relative bg-white shadow-sm rounded-3xl p-2 transform transition duration-300 hover:translate-y-[-10px] hover:shadow-lg h-[15rem] md:h-[10rem]"
                            key={index + 1}
                        >
                            <img
                                src={item.img}
                                className="w-[20%] h-auto mx-auto mb-4 absolute z-10 left-[.7rem] rounded-tl-2xl md:w-[30%] md:rounded-tl-2 md:left-[0rem] md:top-[0rem] top-[1rem] " 
                                alt=""
                            />
                            <div
                            className={`rounded-2xl cursor-pointer ${/* Estilo para móvil y escritorio */ 
                                "absolute inset-0 mmd:relative mmd:inset-auto mmd:w-[98%] mmd:h-[95%] mmd:mx-auto mmd:my-2"
                            }`}
                            style={{ 
                                backgroundImage: `url(${imageMap[item.ods]})`, 
                                backgroundSize: 'cover', 
                                backgroundPosition: 'center', 
                                backgroundRepeat: 'no-repeat',
                                filter: 'grayscale(100%)',
                            }}
                            >
                            </div>
                        </div>
                        
                        ))}
                    </div>

                    </div>
                </div>
                <img
                src={forma}
                alt="background-shape"
                className="w-[55%] absolute top-[15rem] right-[-62rem] origin-left opacity-40 rotate-[-100Deg] overflow-hidden md:hidden"
                />
            </section>
        </>
    );
}
